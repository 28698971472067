import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    compensationTimePagination: (state, action) => {
      state.compensationTimePagination = action.payload;
    },
    taskPagination: (state, action) => {
      state.taskPagination = action.payload;
    },
    missPunchPagination: (state, action) => {
      state.missPunchpagination = action.payload;
    },

    projectPagination: (state, action) => {
      state.projectPagination = action.payload;
    },
    salarySlipPagination: (state, action) => {
      state.salarySlipPagination = action.payload;
    },
    workspacePagination: (state, action) => {
      state.pagination = action.payload;
    },
    workspaceCredientialPagination:(state, action) => {
      state.workspaceCredientialPagination = action.payload;
    },
    workspaceUploadPagination:(state, action) => {
      state.workspaceUploadPagination = action.payload;
    },
  },
});

export const {
  compensationTimePagination,
  taskPagination,
  missPunchPagination,
  projectPagination,
  salarySlipPagination,
  workspacePagination,
  workspaceCredientialPagination ,
  workspaceUploadPagination
} = paginationSlice.actions;

export default paginationSlice.reducer;
